<template>
    <div class="created-dashboard">
        <div class="header-container">
            <h2 class="dashboard-title">Dashboard: {{ dashboardData.name }}</h2>
            <div class="save-button-container">
                <button class="save-button" @click="saveChartSettings">
                    <i class="fas fa-save"></i> Save Changes
                </button>
            </div>
        </div>

        <!-- Conditional Rendering for Table Type -->
        <div v-if="dashboardData.type === 'table'" class="table-container">
            <button @click="downloadExcel" class="download-button">
                <i class="fa fa-download"></i> Download Excel
            </button>
            <table>
                <thead>
                    <tr>
                        <th>Condition</th>
                        <th>Question</th>
                        <th>Answers</th>
                        <th>District</th>
                        <th>Area</th>
                        <th v-for="center in healthCenterNames" :key="center">{{ center }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(question, qIndex) in tableData" :key="qIndex">
                        <template v-for="(response, rIndex) in question.responses" :key="rIndex">
                            <tr>
                                <td v-if="rIndex === 0" :rowspan="getTotalRowspan(question.responses)">
                                    {{ question.condition }}
                                </td>
                                <td v-if="rIndex === 0" :rowspan="getTotalRowspan(question.responses)">
                                    {{ question.question }}
                                </td>
                                <td :rowspan="response.health_centers.length">{{ response.response }}</td>
                                <td>{{ response.health_centers[0].district }}</td>
                                <td>{{ response.health_centers[0].location }}</td>
                                <td v-for="center in healthCenterNames" :key="center">
                                    {{ getHealthCenterCount(response.health_centers, center, center) }}
                                </td>
                            </tr>
                            <template v-for="(center, cIndex) in response.health_centers" :key="cIndex">
                                <tr v-if="cIndex !== 0">
                                    <td>{{ center.district }}</td>
                                    <td>{{ center.location }}</td>
                                    <td v-for="hc in healthCenterNames" :key="hc">
                                        {{ getHealthCenterCount([center], hc, hc) }}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>

        <!-- Grid Layout for Non-Table Types -->
        <div v-else class="grid-container">
            <grid-layout v-if="layout.length > 0" :layout.sync="layout" :col-num="8" :row-height="150"
                :is-draggable="draggable" :is-resizable="resizable" :responsive="responsive" :vertical-compact="true"
                :use-css-transforms="true" @layout-updated="onLayoutUpdated" @resize="resizeCharts">

                <grid-item v-for="(item, index) in layout" :key="item.i" :x="item.x" :y="item.y" :w="item.w" :h="item.h"
                    :i="item.i">
                    <div class="cards">
                        <div class="card-header">
                            <button class="remove-button" @click="removeChart(item.i)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                            <div class="filter-select">
                                <select v-model="dashboardData.chart_types[item.i]" @change="updateChartType(item.i)">
                                    <option disabled value="">Chart Type</option>
                                    <option value="bar">Bar</option>
                                    <option value="line">Line</option>
                                    <option value="doughnut">Doughnut</option>
                                    <option value="funnel">Funnel</option>
                                    <option value="polarEndAngle">Polar End Angle</option>
                                    <option value="stackedHorizontalBar">Stacked Horizontal Bar</option>
                                    <option value="radar">Basic Radar Chart</option>
                                    <option value="table">Table</option>
                                </select>
                            </div>
                        </div>

                        <h3 class="question-title">
                            {{ dashboardData.type === 'hc_graph'
                                ? `Health Center Data: ${dashboardData.charts_data.questions[index].question}`
                                : dashboardData.charts_data.questions[index].question }}
                        </h3>

                        <div v-if="dashboardData.type === 'hc_graph'" class="chart-container"
                            :id="'chart-question-' + index">
                        </div>
                        <div v-else class="chart-container" :id="'chart-' + index">
                        </div>
                    </div>
                </grid-item>
            </grid-layout>
        </div>
    </div>
</template>

<script>
import { GridLayout, GridItem } from "vue3-grid-layout";
import axios from "@/helpers/axios.js";
import * as echarts from "echarts";
import { useToast } from "vue-toastification";
import * as XLSX from "xlsx";
const Toast = useToast();

export default {
    components: {
        GridLayout,
        GridItem,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            dashboardData: {
                name: '',
                charts_data: {
                    questions: []
                },
                chart_types: {},
                type: ''
            },
            tableData: [],
            layout: [],
            draggable: true,
            resizable: true,
            responsive: true,
            healthCenterNames: []
        };
    },
    watch: {
        id(newId) {
            this.fetchDashboardData(newId);
        },
    },
    created() {
        this.fetchDashboardData(this.id);
    },
    mounted() {
        window.addEventListener("resize", this.resizeCharts);
        // Force resize after mount to ensure charts adapt to their containers
        this.$nextTick(() => {
            setTimeout(() => {
                this.resizeCharts();
            }, 100); // Add a small delay to ensure charts render properly
        });
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.resizeCharts);
    },
    methods: {
        onLayoutUpdated(updatedLayout) {
            this.layout = updatedLayout;
            this.resizeCharts();
        },
        async fetchDashboardData(dashboardId) {
            try {
                const response = await axios.get(`/dashboard/${dashboardId}`);
                if (response.data.status) {
                    this.dashboardData = response.data.data;
                    if (this.dashboardData.type === "table") {
                        this.tableData = this.dashboardData.charts_data.questions;
                        this.healthCenterNames = this.extractHealthCenterNames(this.tableData);
                    } else {
                        this.initializeLayout();
                        this.$nextTick(() => {
                            this.initializeCharts();
                            this.resizeCharts();
                        });
                    }
                } else {
                    console.error("Failed to fetch dashboard data");
                }
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        },
        extractHealthCenterNames(data) {
            const healthCenterNamesSet = new Set();
            data.forEach(question => {
                question.responses.forEach(response => {
                    response.health_centers.forEach(center => {
                        healthCenterNamesSet.add(center.health_center);
                    });
                });
            });
            return Array.from(healthCenterNamesSet);
        },

        initializeLayout() {
            if (this.dashboardData.charts_data && this.dashboardData.charts_data.questions) {
                this.layout = this.dashboardData.charts_data.questions.map((question, index) => ({
                    x: index % 2 === 0 ? 0 : 6,
                    y: Math.floor(index / 2),
                    w: 6,
                    h: 4,
                    i: `question-${index}` // Unique ID based on index
                }));
            }
        },

        initializeCharts() {
            this.dashboardData.charts_data.questions.forEach((question, index) => {
                const chartKey = `question-${index}`;

                // Set default chart type to "bar" if not defined
                if (!this.dashboardData.chart_types[chartKey]) {
                    this.dashboardData.chart_types[chartKey] = "Chart Type";
                }

                if (question.responses && question.responses.length > 0) {
                    if (this.dashboardData.type === "hc_graph") {
                        this.renderHealthCenterChart(question, index);
                    } else {
                        this.renderChart(question, index);
                    }
                }
            });

            // Trigger resize after a short delay to ensure charts are displayed correctly
            this.$nextTick(() => {
                setTimeout(() => {
                    this.resizeCharts();
                }, 100);
            });
        },

        renderChart(question, index, chartType) {
            this.$nextTick(() => {
                const chartDom = document.getElementById("chart-" + index);
                if (!chartDom) {
                    console.warn(`Chart DOM not found for question at index ${index}`);
                    return;
                }

                // Reinitialize chart instance
                const chart = echarts.init(chartDom);

                // Get options for the selected chart type, defaulting to "line" if no type is provided
                const selectedChartType = chartType || this.dashboardData.chart_types[question.id.toString()] || "line";
                const options = this.getChartOptions(question, selectedChartType);

                if (Object.keys(options).length > 0) {
                    chart.setOption(options);

                    // Add interactive styles for pie/doughnut charts
                    chart.on('mouseover', (params) => {
                        if (params.seriesType === 'pie' || params.seriesType === 'doughnut') {
                            chart.setOption({
                                graphic: {
                                    type: 'text',
                                    left: 'center',
                                    top: 'center',
                                    style: {
                                        text: `${params.name}`,
                                        fontSize: 32,
                                        fontFamily: 'Roboto, sans-serif',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        fill: '#333',
                                        textShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)',
                                    }
                                }
                            });
                        }
                    });

                    // Clear the center label on mouseout
                    chart.on('mouseout', () => {
                        chart.setOption({
                            graphic: {
                                type: 'text',
                                left: 'center',
                                top: 'center',
                                style: {
                                    text: ''
                                }
                            }
                        });
                    });
                } else {
                    console.warn("Skipping chart rendering due to invalid options.");
                }
            });
        },


        renderHealthCenterChart(question, index, chartType) {
            this.$nextTick(() => {
                const chartDom = document.getElementById("chart-question-" + index);
                if (!chartDom) {
                    console.warn(`Chart DOM not found for health center question at index ${index}`);
                    return;
                }

                const chart = echarts.init(chartDom);
                const selectedChartType = chartType || this.dashboardData.chart_types[`hc_graph-${index}`] || "bar"; // Use the provided or default type
                const options = this.getHealthCenterChartOptions(question, selectedChartType);

                if (Object.keys(options).length > 0) {
                    chart.setOption(options);
                } else {
                    console.warn("Skipping chart rendering due to invalid options.");
                }
            });
        },

        updateChartType(chartId) {
            const index = this.layout.findIndex(item => item.i === chartId);
            if (index !== -1) {
                const question = this.dashboardData.charts_data.questions[index];
                const selectedType = this.dashboardData.chart_types[chartId] || "line"; // Default to "line" if no type specified

                // Dispose of any existing chart instance to ensure a clean render
                const chartDomId = this.dashboardData.type === "hc_graph" ? `chart-question-${index}` : `chart-${index}`;
                const chartDom = document.getElementById(chartDomId);
                if (chartDom) {
                    const existingChart = echarts.getInstanceByDom(chartDom);
                    if (existingChart) {
                        existingChart.dispose(); // Clear previous chart instance
                    }
                }

                // Render the chart with the updated type
                if (this.dashboardData.type === "hc_graph") {
                    this.renderHealthCenterChart(question, index, selectedType); // Pass the new type to renderHealthCenterChart
                } else {
                    this.renderChart(question, index, selectedType); // Pass the new type to renderChart
                }
            }
        },
        removeChart(chartId) {
            this.layout = this.layout.filter(item => item.i !== chartId);
            delete this.dashboardData.chart_types[chartId];
        },
        getChartOptions(question, chartType) {
            const seriesData = question.responses.map((response, index) => ({
                name: response.response,
                value: response.count,
            }));

            switch (chartType) {
                case 'doughnut':
                    return {
                        tooltip: {
                            trigger: 'item',
                            formatter: (params) => {
                                return `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${params.color};margin-right:5px;"></span> ${params.name}: ${params.value} (${params.percent}%)`;
                            }
                        },
                        legend: {
                            orient: 'horizontal',
                            top: 'top',
                            left: 'center'
                        },
                        graphic: {
                            type: 'text',
                            left: 'center',
                            top: '50%',
                            z: 100,
                            style: {
                                text: '',  // Initially empty; dynamically updated on hover
                                textAlign: 'center',
                                fontSize: 32,
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                fill: '#333',
                                textShadowBlur: 8,
                                textShadowOffsetX: 2,
                                textShadowOffsetY: 2,
                                textShadowColor: 'rgba(0, 0, 0, 0.25)'
                            }
                        },
                        series: [
                            {
                                name: question.question,
                                type: 'pie',
                                radius: ['40%', '70%'],
                                data: seriesData,
                                label: { show: false }  // Hides labels around the pie sections
                            }
                        ],
                        color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946']
                    };

                case 'line':
                    return {
                        tooltip: {
                            trigger: 'axis',
                            formatter: (params) => {
                                const dataPoint = params[0];
                                return `${dataPoint.name}: ${dataPoint.value}`;
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            axisLabel: { interval: 0, rotate: 30 }
                        },
                        yAxis: { type: 'value' },
                        series: [
                            {
                                data: seriesData.map(item => item.value),
                                type: 'line',
                                smooth: true,
                                lineStyle: { width: 3, color: '#5470C6' },
                                itemStyle: { color: '#5470C6', borderColor: '#333', borderWidth: 1 },
                                symbol: 'circle',
                                symbolSize: 8
                            }
                        ]
                    };

                case 'funnel':
                    return {
                        tooltip: { trigger: 'item' },
                        series: [
                            {
                                name: question.question,
                                type: 'funnel',
                                data: seriesData
                            }
                        ]
                    };

                case 'polarEndAngle':
                    return {
                        angleAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            startAngle: 75, // Adjust the angle as needed
                        },
                        radiusAxis: {},
                        polar: {},
                        tooltip: { trigger: 'item' },
                        series: [{
                            type: 'bar',
                            data: seriesData.map(item => item.value),
                            coordinateSystem: 'polar',
                            itemStyle: { color: '#4C78A8' }
                        }],
                    };

                case 'stackedHorizontalBar':
                    return {
                        tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
                        xAxis: { type: 'value' },
                        yAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            axisLabel: { interval: 0, rotate: 30 },
                        },
                        series: [{
                            data: seriesData.map(item => item.value),
                            type: 'bar',
                            stack: 'total',
                            itemStyle: { borderRadius: [0, 10, 10, 0] },
                        }],
                        color: ['#FF5A5F', '#57CC99', '#FFD166', '#4C78A8'],
                    };

                case 'radar':
                    return {
                        tooltip: {},
                        radar: {
                            indicator: question.responses.map(response => ({
                                name: response.response,
                                max: Math.max(...seriesData.map(item => item.value)) * 1.2,
                            })),
                            radius: 60,
                        },
                        series: [{
                            name: question.question,
                            type: 'radar',
                            data: [{ value: seriesData.map(item => item.value), name: question.question }],
                            itemStyle: { color: '#5470C6' },
                            areaStyle: { opacity: 0.3 },
                        }],
                    };

                case 'bar':
                default:
                    return {
                        tooltip: {
                            trigger: 'axis',
                            formatter: (params) => {
                                const dataPoint = params[0];
                                return `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${dataPoint.color};margin-right:5px;"></span> ${dataPoint.name}: ${dataPoint.value}`;
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            axisLabel: { interval: 0, rotate: 30 }
                        },
                        yAxis: { type: 'value' },
                        series: [{
                            data: seriesData.map(item => item.value),
                            type: 'bar',
                            itemStyle: { borderRadius: [10, 10, 0, 0] }
                        }]
                    };
            }
        },
        getHealthCenterChartOptions(question, chartType) {
            if (chartType === 'doughnut') {
                // Doughnut chart setup
                const seriesData = question.responses.flatMap((response) =>
                    response.health_centers.map((hc) => ({
                        name: `${response.response} - ${hc.health_center}`,
                        value: hc.count,
                    }))
                );

                return {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                        data: seriesData.map(item => item.name)
                    },
                    series: [
                        {
                            name: question.question,
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            label: { show: false, position: 'center' },
                            emphasis: { label: { show: true, fontSize: '16', fontWeight: 'bold' } },
                            labelLine: { show: false },
                            data: seriesData,
                            itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 }
                        }
                    ],
                    color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946']
                };
            } else if (chartType === 'polarEndAngle') {
                // Polar End Angle setup
                const healthCenters = Array.from(new Set(
                    question.responses.flatMap(response => response.health_centers.map(hc => hc.health_center))
                ));

                const seriesData = question.responses.map(response => ({
                    name: response.response,
                    type: 'bar',
                    data: healthCenters.map(hcName => {
                        const hc = response.health_centers.find(hc => hc.health_center === hcName);
                        return hc ? hc.count : 0;
                    }),
                    coordinateSystem: 'polar'
                }));

                return {
                    angleAxis: { type: 'category', data: healthCenters, startAngle: 75 },
                    radiusAxis: {},
                    polar: {},
                    tooltip: { trigger: 'item' },
                    series: seriesData,
                    color: ['#FF5A5F', '#57CC99', '#4C78A8', '#FFD166'],
                    legend: { orient: 'vertical', left: 'left', data: question.responses.map(r => r.response) }
                };

            } else if (chartType === 'stackedHorizontalBar') {
                // Stacked Horizontal Bar setup
                const healthCenters = Array.from(new Set(
                    question.responses.flatMap(response => response.health_centers.map(hc => hc.health_center))
                ));

                const seriesData = question.responses.map(response => ({
                    name: response.response,
                    type: 'bar',
                    stack: 'total',
                    data: healthCenters.map(hcName => {
                        const hc = response.health_centers.find(hc => hc.health_center === hcName);
                        return hc ? hc.count : 0;
                    }),
                    itemStyle: { borderRadius: [0, 10, 10, 0] }
                }));

                return {
                    tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
                    xAxis: { type: 'value' },
                    yAxis: {
                        type: 'category',
                        data: healthCenters,
                        axisLabel: { interval: 0, rotate: 30 }
                    },
                    legend: { data: question.responses.map(r => r.response) },
                    series: seriesData,
                    color: ['#FF5A5F', '#57CC99', '#4C78A8', '#FFD166']
                };

            } else if (chartType === 'radar') {
                // Radar chart setup
                const healthCenters = Array.from(new Set(
                    question.responses.flatMap(response => response.health_centers.map(hc => hc.health_center))
                ));

                const seriesData = question.responses.map(response => ({
                    value: healthCenters.map(hcName => {
                        const hc = response.health_centers.find(hc => hc.health_center === hcName);
                        return hc ? hc.count : 0;
                    }),
                    name: response.response
                }));

                return {
                    tooltip: {},
                    radar: {
                        indicator: healthCenters.map(hc => ({
                            name: hc,
                            max: Math.max(...seriesData.flatMap(item => item.value)) * 1.2
                        })),
                        radius: 60
                    },
                    series: [{
                        type: 'radar',
                        data: seriesData,
                        areaStyle: { opacity: 0.3 }
                    }],
                    color: ['#5470C6', '#FF5A5F', '#4C78A8', '#FFD166']
                };

            } else {
                // Default handling for bar or line chart types
                const healthCenters = Array.from(new Set(
                    question.responses.flatMap(response => response.health_centers.map(hc => hc.health_center))
                ));

                const seriesData = question.responses.map(response => ({
                    name: response.response,
                    type: chartType,
                    data: healthCenters.map(hcName => {
                        const hc = response.health_centers.find(hc => hc.health_center === hcName);
                        return hc ? hc.count : 0;
                    }),
                    barGap: "10%", // Adjusts spacing between bars of different series
                    label: { show: false },
                    itemStyle: { borderRadius: [10, 10, 0, 0] }
                }));

                return {
                    tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
                    legend: { data: question.responses.map(r => r.response), top: 'top' },
                    xAxis: { type: 'category', data: healthCenters, axisLabel: { rotate: 45, interval: 0 } },
                    yAxis: { type: 'value', name: 'Count' },
                    series: seriesData,
                    color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946']
                };
            }
        },

        resizeCharts() {
            this.dashboardData.charts_data.questions.forEach((question, index) => {
                // Normal chart resizing
                const normalChartDom = document.getElementById("chart-" + index);
                if (normalChartDom) {
                    const normalChart = echarts.getInstanceByDom(normalChartDom);
                    if (normalChart) normalChart.resize();
                }

                // Health center chart resizing
                const healthCenterChartDom = document.getElementById("chart-question-" + index);
                if (healthCenterChartDom) {
                    const healthCenterChart = echarts.getInstanceByDom(healthCenterChartDom);
                    if (healthCenterChart) healthCenterChart.resize();
                }
            });
        },
        saveChartSettings() {
            // Prepare data according to backend structure
            const saveData = {
                charts_data: this.dashboardData.charts_data,
                chart_sizes: this.dashboardData.chart_sizes,
                chart_types: this.dashboardData.chart_types
            };

            // Use the `id` prop directly as the dashboard ID
            const dashboardId = this.id;
            console.log("Dashboard:", chart_types);

            // Send a POST request to save settings
            axios
                .post(`/dashboard/${dashboardId}/save-settings`, saveData)
                .then((response) => {
                    if (response.data.status) {
                        Toast.success("Settings saved successfully");
                    } else {
                        console.error("Unexpected response:", response);
                    }
                })
                .catch((error) => {
                    console.error("Error saving settings:", error);
                });
        },
        downloadExcel() {
            const ws = XLSX.utils.aoa_to_sheet(this.prepareExcelData());
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Health Center Report");
            const merges = this.getMerges();
            if (merges.length) { ws['!merges'] = merges; }
            XLSX.writeFile(wb, "health_center_report.xlsx");
        },
        prepareExcelData() {
            const data = [["Condition", "Question", "Answers", "District", "Area", ...this.healthCenterNames]];
            this.tableData.forEach(question => {
                question.responses.forEach((response, rIndex) => {
                    response.health_centers.forEach((center, cIndex) => {
                        const row = [];
                        if (rIndex === 0 && cIndex === 0) { row.push(question.condition, question.question); }
                        else { row.push("", ""); }
                        if (cIndex === 0) { row.push(response.response); }
                        else { row.push(""); }
                        row.push(center.district, center.location);
                        this.healthCenterNames.forEach(name => row.push(this.getHealthCenterCount(response.health_centers, name, center.health_center)));
                        data.push(row);
                    });
                });
            });
            return data;
        },
        getMerges() {
            const merges = [];
            let conditionStart = 1, questionStart = 1, responseStart = 1;
            this.tableData.forEach(question => {
                const responseCount = this.getTotalRowspan(question.responses);
                merges.push({ s: { r: conditionStart, c: 0 }, e: { r: conditionStart + responseCount - 1, c: 0 } });
                merges.push({ s: { r: questionStart, c: 1 }, e: { r: questionStart + responseCount - 1, c: 1 } });
                question.responses.forEach(response => {
                    merges.push({ s: { r: responseStart, c: 2 }, e: { r: responseStart + response.health_centers.length - 1, c: 2 } });
                    responseStart += response.health_centers.length;
                });
                conditionStart += responseCount;
                questionStart += responseCount;
            });
            return merges;
        },

        getTotalRowspan(responses) {
            return responses.reduce((total, response) => total + response.health_centers.length, 0);
        },
        getHealthCenterCount(centers, centerName, currentCenterName) {
            if (currentCenterName === centerName) {
                const center = centers.find(center => center.health_center === centerName);
                return center ? center.count : 0;
            }
            return 0;
        },

    },
};
</script>



<style scoped>
.grid-container {
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px;
}

.vue-grid-layout {
    width: 100%;
    background: #f5f5f5;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.cards {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
}

.remove-button {
    background: transparent;
    border: none;
    color: #e74c3c;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
}

.remove-button:hover {
    color: #c0392b;
}

.filter-select select {
    padding: 8px 40px;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.filter-select select:focus {
    outline: none;
    border-color: #888;
}

.question-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.chart-container {
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.save-button-container {
    margin: 0;
}

.save-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 13px 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.save-button:hover {
    background-color: #0056b3;
}

.save-button i {
    font-size: 18px;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    vertical-align: top;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

.download-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    margin-bottom: 12px;
}

.download-button:hover {
    background-color: #0056b3;
}

.download-button:active {
    background-color: #004085;
}
</style>
