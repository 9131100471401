<template>
  <div class="dashboard-container">
    <div class="row">
      <div class="col">
        <!-- <h2>Hi {{ username }}</h2> -->
        <h2>Total Surveys done Vs Location</h2>
      </div>
    </div>
    <div class="row cards-row">
      <!-- Patient Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'patient_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Patient Tool</p>
              <span class="main-count">
                <p class="count">{{ patientToolCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="patientToolProvince" @change="fetchDistrictsAndCount('patientTool', 1)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="patientToolDistrict" @change="fetchCount('patientTool', 1)">
                <option disabled value="">Select District</option>
                <option v-for="district in patientToolDistricts" :key="district.id" :value="district.id">{{
                  district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Community Based Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'community_based_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Community Based</p>
              <span class="main-count">
                <p class="count">{{ communityBasedCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="communityBasedProvince" @change="fetchDistrictsAndCount('communityBased', 3)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="communityBasedDistrict" @change="fetchCount('communityBased', 3)">
                <option disabled value="">Select District</option>
                <option v-for="district in communityBasedDistricts" :key="district.id" :value="district.id">{{
                  district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Health Facility Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'health_facility_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Health Facility</p>
              <span class="main-count">
                <p class="count">{{ healthFacilityCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="healthFacilityProvince" @change="fetchDistrictsAndCount('healthFacility', 2)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="healthFacilityDistrict" @change="fetchCount('healthFacility', 2)">
                <option disabled value="">Select District</option>
                <option v-for="district in healthFacilityDistricts" :key="district.id" :value="district.id">{{
                  district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- Observation Tool Card -->
      <div class="col" v-if="hasPermission('tools', 'observation_tool')">
        <div class="card">
          <div class="card-content">
            <div class="c1">
              <p>Observation</p>
              <span class="main-count">
                <p class="count">{{ serviceProviderCount }}</p>
              </span>
            </div>
            <div class="container">
              <select v-model="serviceProviderProvince" @change="fetchDistrictsAndCount('serviceProvider', 4)">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <div class="container">
              <select v-model="serviceProviderDistrict" @change="fetchCount('serviceProvider', 4)">
                <option disabled value="">Select District</option>
                <option v-for="district in serviceProviderDistricts" :key="district.id" :value="district.id">{{
                  district.name }}</option>
              </select>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col tabs">
        <div class="content">
          <ul class="nav-tabs">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'Main' }" @click.prevent="setTab('Main')"
                href="#">Main</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'Customize' }" @click.prevent="setTab('Customize')"
                href="#">Customize</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'HealthCenterAnalysis' }"
                @click.prevent="setTab('HealthCenterAnalysis')" href="#">Health Center Analysis</a>
            </li>
            <li v-if="dashboards && dashboards.length" v-for="dashboard in dashboards" :key="dashboard.id"
              class="nav-item dashboard-item">
              <a class="nav-link" :class="{ active: activeTab === 'Dashboard-' + dashboard.id }"
                @click.prevent="selectDashboard(dashboard.id)" href="#">
                {{ dashboard.name }}
              </a>
              <svg style="cursor: pointer;" @click="confirmDelete(dashboard.id)" width="38" height="38"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM8.96965 8.96967C9.26254 8.67678 9.73742 8.67678 10.0303 8.96967L12 10.9394L13.9696 8.96969C14.2625 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0303L13.0606 12L15.0303 13.9697C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73744 15.3232 9.26256 15.3232 8.96967 15.0303C8.67678 14.7374 8.67678 14.2626 8.96967 13.9697L10.9393 12L8.96965 10.0303C8.67676 9.73744 8.67676 9.26256 8.96965 8.96967Z"
                  fill="#1C3454" fill-opacity="0.26" />
              </svg>
            </li>
          </ul>

          <div class="tab-content mt-3">
            <!-- Main Tab Content -->
            <div v-if="activeTab === 'Main'">
              <div class="row">
                <div class="col1 usersCol">
                  <conditionAnalyticsVue />
                </div>
                <div class="col conditonAnalytics">
                  <urgencyAnalysis />
                </div>
              </div>
              <div class="row">
                <div class="col WeeklyDiagnosis">
                  <WeeklyDiagnosisVue />
                </div>
              </div>
              <div class="row">
                <div class="col questionCol">
                  <questionAnalyticsVue />
                </div>
              </div>
              <div class="row">
                <div class="col QuestionAnalysis">
                  <QuestionAnalysis />
                </div>
              </div>
            </div>

            <!-- Customize Tab Content -->
            <div v-if="activeTab === 'Customize'">
              <CustomizeAnalysis />
            </div>

            <!-- Health Center Analysis Tab Content -->
            <div v-if="activeTab === 'HealthCenterAnalysis'">
              <HealthCenterAnalysis />
            </div>

            <!-- Dynamic Dashboard Content -->
            <div v-if="selectedDashboardId">
              <CreatedDashboards :id="selectedDashboardId" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isNameModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeNameModal">&times;</span>
        <h3>Name Your Dashboard</h3>
        <input type="text" v-model="dashboardName" placeholder="Enter dashboard name" required />
        <div class="modal-actions">
          <button class="save" @click="saveDashboardWithName">Save</button>
          <button class="cancel" @click="closeNameModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { mapGetters } from "vuex";
import axiosRetry from "axios-retry";
import conditionAnalyticsVue from "./charts/conditionAnalytics.vue";
import userAnalyticsVue from "./charts/userAnalytics.vue";
import urgencyAnalysis from "./charts/urgencyAnalysis.vue";
import WeeklyDiagnosisVue from "./charts/WeeklyDiagnosis.vue";
import questionAnalyticsVue from "./charts/questionAnalytics.vue";
import QuestionAnalysis from "./table/QuestionAnalysis.vue";
import HealthCenterAnalysis from "./customize/HealthCenterAnalysis.vue";
import CustomizeAnalysis from "./customize/Customize.vue";
import CreatedDashboards from "./customize/CreatedDashboards.vue";
import * as echarts from "echarts";
import * as XLSX from "xlsx";
import { useToast } from "vue-toastification";
// import { GridLayout, GridItem } from "vue-grid-layout";
import { GridLayout, GridItem } from "vue3-grid-layout";

const Toast = useToast();

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 2000,
  retryCondition: (error) => error.response && error.response.status === 429,
});

export default {
  components: {
    GridLayout,
    GridItem,
    conditionAnalyticsVue,
    userAnalyticsVue,
    urgencyAnalysis,
    WeeklyDiagnosisVue,
    questionAnalyticsVue,
    QuestionAnalysis,
    HealthCenterAnalysis,
    CustomizeAnalysis,
    CreatedDashboards,

  },
  data() {
    return {
      expandedProvince: null,
      loadingDistricts: false,
      selectedDashboardId: null,
      activeTab: "Main",
      activeCustomizeTab: "Locations",
      username: localStorage.getItem("fullname"),
      userId: 1,
      dashboards: [],
      patientToolCount: 0,
      communityBasedCount: 0,
      healthFacilityCount: 0,
      serviceProviderCount: 0,
      provinces: [],
      selectedProvince: null,
      selectedProvinceName: "",
      districts: [],
      selectedDistricts: [],
      districtsByProvince: {},
      tools: [],
      selectedTools: [],
      conditions: [],
      selectedConditions: [],
      questions: [],
      selectedQuestions: [],
      fullQuestion: "",
      showModal: false,
      selectedUrgency: [],
      selectedStatus: [],
      chartsData: {
        questions: [],
        urgencies: {},
        statuses: {},
      },
      draggable: true,
      resizable: true,
      responsive: true,
      layout: [],
      isNameModalOpen: false,
      dashboardName: "",
      patientToolProvince: "",
      patientToolDistrict: "",
      patientToolDistricts: [],
      communityBasedProvince: "",
      communityBasedDistrict: "",
      communityBasedDistricts: [],
      healthFacilityProvince: "",
      healthFacilityDistrict: "",
      healthFacilityDistricts: [],
      serviceProviderProvince: "",
      serviceProviderDistrict: "",
      serviceProviderDistricts: [],
      chartTypes: {},
      chartInstances: {},
      dropdowns: {},
      chartSizes: {},
      tableData: [],
      hcGraphData: [],
      healthCenterNames: [],
      showChartModal: false,
    };
  },
  mounted() {
    this.fetchCounts();
    this.fetchProvinces();
    this.fetchTools();
    this.fetchConditions();
    this.fetchDashboards();
    this.toggleAccordion();
    this.healthCenterNames = this.extractHealthCenterNames(this.tableData);
    this.initializeChartTypes();
    // this.renderHealthCenterCharts();
    // this.renderCharts();
    this.$nextTick(() => {
      this.renderHealthCenterCharts();
      this.renderCharts();
    });
    this.layout = this.chartsData.questions.map((chart, index) => ({
      i: chart.id ? chart.id.toString() : `chart-${index}`,
      x: (index % 2) * 6,  // Alternate between columns 0 and 6 for two per row
      y: Math.floor(index / 2) * 3,  // Position in rows
      w: 6,  // Two items per row
      h: 3,
    }));
  },
  beforeDestroy() {
    this.destroyCharts();
  },
  methods: {
    initializeChartTypes() {
      this.chartTypes = {
        ...this.hcGraphData.reduce((types, chart, index) => {
          types[`hc_graph-${index}`] = "bar";
          return types;
        }, {}),
      };
    },
    setTab(tabName) {
      this.activeTab = tabName;
      this.selectedDashboardId = null;
    },
    selectDashboard(dashboardId) {
      // If the selected dashboard ID is the same, do nothing
      if (this.selectedDashboardId === dashboardId) {
        this.selectedDashboardId = null;
        this.activeTab = null;
        return;
      }

      // Update the selected dashboard ID and active tab
      this.selectedDashboardId = dashboardId;
      this.activeTab = 'Dashboard-' + dashboardId;
    },
    toggleDropdown(index) {
      this.dropdowns[index] = !this.dropdowns[index];
    },
    updateChartType(chartId, index, type) {
      this.chartTypes[chartId] = type;
      this.dropdowns[index] = false;
      this.renderCharts();
    },
    getChartOptions(type, data) {
      return {
        title: {
          text: data.question,
          left: "center",
        },
        tooltip: {
          trigger: type === "line" || type === "bar" ? "axis" : "item",
          formatter: type === "doughnut" || type === "pie" || type === "polarBar" ? "{b}: {c} ({d}%)" : undefined,
        },
        xAxis: (type === "bar" || type === "line") ? {
          type: "category",
          data: data.labels,
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
        } : undefined,
        yAxis: (type === "bar" || type === "line") ? {
          type: "value",
        } : undefined,
        series: (() => {
          switch (type) {
            case "line":
              return [{ data: data.values, type: "line", smooth: true }];
            case "bar":
              return [{ data: data.values, type: "bar" }];
            case "doughnut":
              return [{ type: "pie", radius: ["50%", "70%"], data: data.values.map((value, idx) => ({ value, name: data.labels[idx] })) }];
            case "polarBar":
              return [{ type: "bar", coordinateSystem: "polar", data: data.values.map((value, idx) => ({ value, name: data.labels[idx] })) }];
            default:
              return [];
          }
        })(),
      };
    },
    renderCharts() {
      this.$nextTick(() => {
        this.chartsData.questions.forEach((question) => {
          const container = document.getElementById(`chart-${question.id}`);
          if (!container) return;

          if (this.chartInstances[`chart-${question.id}`]) {
            this.chartInstances[`chart-${question.id}`].dispose();
          }

          const chart = echarts.init(container);
          const options = this.getChartOptions(this.chartTypes[question.id] || "bar", {
            question: question.question,
            labels: question.responses.map((response) => response.response),
            values: question.responses.map((response) => response.count),
          });

          chart.setOption(options);
          chart.resize(); // Ensures the chart resizes to fit the container
          this.chartInstances[`chart-${question.id}`] = chart;
        });
      });
    },
    updateHealthCenterChartType(chartTypeKey, index, type) {
      this.chartTypes = { ...this.chartTypes, [`hc_graph-${index}`]: type };
      this.dropdowns[index] = false;
      this.renderHealthCenterCharts();
    },
    toggleAccordion() {
      setTimeout(() => {
        var acc = document.getElementsByClassName("accordion");
        var i;
        for (i = 0; i < acc.length; i++) {
          acc[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
            } else {
              panel.style.maxHeight = panel.scrollHeight + "px";
            }
          });
        }
      }, 1000);
    },
    fetchCounts() {
      this.fetchCount("patientTool", 1);
      this.fetchCount("communityBased", 3);
      this.fetchCount("healthFacility", 2);
      this.fetchCount("serviceProvider", 4);
    },
    fetchDistrictsAndCount(tool, toolId) {
      this.fetchDistricts(tool);
      this.fetchCount(tool, toolId);
    },
    fetchProvinces() {
      axios
        .get("/province")
        .then((response) => {
          this.provinces = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching provinces:", error);
        });
    },
    fetchDistricts(tool) {
      let selectedProvince = "";
      if (tool === "patientTool") {
        selectedProvince = this.patientToolProvince;
      } else if (tool === "communityBased") {
        selectedProvince = this.communityBasedProvince;
      } else if (tool === "healthFacility") {
        selectedProvince = this.healthFacilityProvince;
      } else if (tool === "serviceProvider") {
        selectedProvince = this.serviceProviderProvince;
      }
      if (selectedProvince) {
        axios
          .get(`/districts/${selectedProvince}`)
          .then((response) => {
            if (tool === "patientTool") {
              this.patientToolDistricts = response.data.data;
              this.patientToolDistrict = "";
            } else if (tool === "communityBased") {
              this.communityBasedDistricts = response.data.data;
              this.communityBasedDistrict = "";
            } else if (tool === "healthFacility") {
              this.healthFacilityDistricts = response.data.data;
              this.healthFacilityDistrict = "";
            } else if (tool === "serviceProvider") {
              this.serviceProviderDistricts = response.data.data;
              this.serviceProviderDistrict = "";
            }
          })
          .catch((error) => {
            console.error("Error fetching districts:", error);
          });
      }
    },
    toggleProvince(provinceId) {
      if (this.expandedProvince === provinceId) {
        this.expandedProvince = null;
      } else {
        this.expandedProvince = provinceId;
        if (!this.districtsByProvince[provinceId]) {
          this.fetchCustomDistricts(provinceId);
        }
      }
    },
    fetchCustomDistricts(provinceId) {
      if (this.expandedProvince === provinceId) {
        this.expandedProvince = null;
      } else {
        this.expandedProvince = provinceId;
        this.loadingDistricts = true;
        axios
          .get(`/districts/${provinceId}`)
          .then((response) => {
            this.districts = response.data.data;
            this.loadingDistricts = false;
          })
          .catch((error) => {
            console.error("Error fetching districts:", error);
            this.loadingDistricts = false;
          });
      }
    },
    getDistricts(provinceId) {
      return this.districtsByProvince[provinceId] || [];
    },
    panelHeight(provinceId) {
      const districts = this.getDistricts(provinceId);
      return districts.length * 40 + "px";
    },
    fetchConditions() {
      axios
        .get("/conditions")
        .then((response) => {
          if (response.data.status) {
            this.conditions = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching conditions:", error);
        });
    },
    fetchQuestions() {
      const requestData = {
        tool_id: this.selectedTools,
        condition_id: this.selectedConditions,
        district_id: this.selectedDistricts,
      };
      axios
        .post("/customized-questions", requestData)
        .then((response) => {
          if (response.data.status) {
            this.questions = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching questions:", error);
        });
    },
    fetchTools() {
      axios
        .get("/tools")
        .then((response) => {
          if (response.data.status) {
            this.tools = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching tools:", error);
        });
    },
    fetchCount(tool, toolId) {
      let selectedProvince = "";
      let selectedDistrict = "";
      if (tool === "patientTool") {
        selectedProvince = this.patientToolProvince;
        selectedDistrict = this.patientToolDistrict;
      } else if (tool === "communityBased") {
        selectedProvince = this.communityBasedProvince;
        selectedDistrict = this.communityBasedDistrict;
      } else if (tool === "healthFacility") {
        selectedProvince = this.healthFacilityProvince;
        selectedDistrict = this.healthFacilityDistrict;
      } else if (tool === "serviceProvider") {
        selectedProvince = this.serviceProviderProvince;
        selectedDistrict = this.serviceProviderDistrict;
      }
      const requestData = {
        tool_id: toolId,
      };
      if (selectedProvince) {
        requestData.province_id = selectedProvince;
      }
      if (selectedDistrict) {
        requestData.district_id = selectedDistrict;
      }
      axios
        .post("admin/count-participants", requestData)
        .then((response) => {
          if (response.status === 200 && response.data.status) {
            if (tool === "patientTool") {
              this.patientToolCount = response.data.data.count;
            } else if (tool === "communityBased") {
              this.communityBasedCount = response.data.data.count;
            } else if (tool === "healthFacility") {
              this.healthFacilityCount = response.data.data.count;
            } else if (tool === "serviceProvider") {
              this.serviceProviderCount = response.data.data.count;
            }
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.error("Error fetching participant count:", error.response.data);
          } else {
            console.error("Error fetching participant count:", error.message);
          }
        });
    },
    truncateText(text, length) {
      return text.length > length ? text.slice(0, length) : text;
    },
    showFullQuestion(question) {
      this.fullQuestion = question.question;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.fullQuestion = "";
    },
    removeDistrict(districtId) {
      this.selectedDistricts = this.selectedDistricts.filter((id) => id !== districtId);
    },
    removeTool(toolId) {
      this.selectedTools = this.selectedTools.filter((id) => id !== toolId);
    },
    removeCondition(conditionId) {
      this.selectedConditions = this.selectedConditions.filter((id) => id !== conditionId);
    },
    removeQuestion(questionId) {
      this.selectedQuestions = this.selectedQuestions.filter((id) => id !== questionId);
    },
    removeUrgency(urgency) {
      this.selectedUrgency = this.selectedUrgency.filter((item) => item !== urgency);
    },
    removeStatus(status) {
      this.selectedStatus = this.selectedStatus.filter((item) => item !== status);
    },
    resetSelections() {
      this.selectedDistricts = [];
      this.selectedTools = [];
      this.selectedConditions = [];
      this.selectedQuestions = [];
      this.selectedUrgency = [];
      this.selectedStatus = [];
    },
    openNameModal() {
      this.isNameModalOpen = true;
    },
    closeNameModal() {
      this.isNameModalOpen = false;
      this.dashboardName = "";
    },
    saveDashboard() {
      this.showChartModal = false;
      this.openNameModal();
    },
    saveDashboardWithName() {
      if (!this.dashboardName) {
        Toast.error("Please enter a name for the dashboard.");
        return;
      }
      if (!this.selectedTools.length || !this.selectedConditions.length || !this.selectedDistricts.length) {
        Toast.error("Please select tools, conditions, and districts before saving the dashboard.");
        return;
      }
      const dashboardData = {
        user_id: this.userId,
        name: this.dashboardName,
        tool_id: this.selectedTools,
        condition_id: this.selectedConditions,
        district_id: this.selectedDistricts,
        questions: this.selectedQuestions,
        urgencies: this.selectedUrgency,
        statuses: this.selectedStatus,
        charts_data: this.chartsData,
        chart_sizes: this.chartSizes, // Save sizes
        chart_types: this.chartTypes, // Save types
        type: 'graph'
      };
      axios
        .post("/save-dashboard", dashboardData)
        .then((response) => {
          if (response.data.status) {
            // this.fetchDashboards();
            this.closeNameModal();
            Toast.success("Dashboard saved successfully");
            window.location.reload();
            // window.location.href = window.location.href;

          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error saving dashboard:", error);
        });
    },
    fetchDashboards() {
      axios
        .get(`/dashboards?user_id=${this.userId}`)
        .then((response) => {
          if (response.data.status) {
            this.dashboards = response.data.data;
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching dashboards:", error);
        });
    },
    loadDashboard(dashboardId) {
      axios
        .get(`/dashboard/${dashboardId}`)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data;
            this.selectedTools = data.tool_id;
            this.selectedConditions = data.condition_id;
            this.selectedDistricts = data.district_id;
            this.selectedQuestions = data.questions;
            this.selectedUrgency = data.urgencies;
            this.selectedStatus = data.statuses;
            this.chartsData = data.charts_data;
            this.chartSizes = data.chart_sizes || {};
            this.chartTypes = data.chart_types || {};
            this.activeTab = "Dashboard-" + dashboardId;
            this.selectedType = data.type || 'table';
            this.destroyCharts();
            if (this.selectedType === 'graph') {
              this.renderCharts();  // Simple graph
            } else if (this.selectedType === 'hc_graph') {
              this.hcGraphData = data.charts_data.questions;
              this.renderHealthCenterCharts();  // More complex hc_graph
            } else {
              this.tableData = data.charts_data.questions;
              this.healthCenterNames = this.extractHealthCenterNames(this.tableData);
            }
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error loading dashboard:", error);
        });
    },
    previewCharts() {
      this.createCharts();
      this.showChartModal = true;
    },
    closeChartModal() {
      this.showChartModal = false;
    },
    createCharts() {
      const requestData = {
        tool_id: this.selectedTools,
        condition_id: this.selectedConditions,
        district_id: this.selectedDistricts,
        questions: this.selectedQuestions,
        urgencies: this.selectedUrgency,
        statuses: this.selectedStatus,
      };
      axios
        .post("/questions-with-answers-chart", requestData)
        .then((response) => {
          if (response.data.status) {
            this.chartsData.questions = response.data.data.questions;
            this.chartsData.urgencies = response.data.data.urgencies;
            this.chartsData.statuses = response.data.data.statuses;
            this.renderCharts();
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error creating charts:", error);
        });
    },
    destroyCharts() {
      Object.keys(this.chartInstances).forEach((key) => {
        if (this.chartInstances[key]) {
          this.chartInstances[key].dispose();
          delete this.chartInstances[key];
        }
      });
    },
    getHealthCenterChartOptions(type, data) {
      // Ensure data structure is valid
      if (!data || !data.values || !data.values.length || !data.labels || !data.labels.length) {
        console.error("Invalid data structure:", data);
        return {};
      }

      const labelCount = data.labels.length;
      const bottomPadding = labelCount > 5 ? 90 : 50;

      const options = {
        title: {
          text: data.question,
          left: 'center',
          top: 10, // Controls the top margin of the title itself
          padding: [10, 0, 0, 0], // Adds space below the title
          textStyle: {
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        grid: type === 'line' || type === 'bar' ? {
          top: 80, // Adjusted top padding to create space between the title and the chart
          left: 20,
          right: 20,
          bottom: bottomPadding,
          containLabel: true,
        } : undefined,
        polar: type === 'polarBar' ? {} : undefined,
        angleAxis: type === 'polarBar' ? {
          type: 'category',
          data: data.labels,
          z: 10,
        } : undefined,
        radiusAxis: type === 'polarBar' ? {
          type: 'value',
        } : undefined,
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}', // Customize the format as needed
        },
        xAxis: (type === 'bar' || type === 'line') ? {
          type: 'category',
          data: data.labels,
          axisLabel: {
            interval: 0,
            rotate: 45,
            fontSize: 10,
            color: '#666',
            overflow: 'truncate',
            width: 80,
          },
        } : undefined,
        yAxis: (type === 'bar' || type === 'line') ? {
          type: 'value',
          axisLabel: {
            fontSize: 10,
            color: '#666',
          },
          splitLine: { show: false },
        } : undefined,
        series: (() => {
          switch (type) {
            case 'line':
              return data.values.map((centerData, idx) => ({
                name: data.healthCenters[idx],
                data: centerData,
                type: 'line',
                smooth: true,
                itemStyle: { color: ['#5470C6', '#91CC75', '#FAC858', '#EE6666'][idx % 4] },
              }));
            case 'bar':
              return data.values.map((centerData, idx) => ({
                name: data.healthCenters[idx],
                data: centerData,
                type: 'bar',
                itemStyle: {
                  borderRadius: [10, 10, 0, 0], // Rounded top corners
                  color: ['#5470C6', '#91CC75', '#FAC858', '#EE6666'][idx % 4],
                },
                barWidth: '20%',
              }));
            case 'polarBar':
              return data.values.map((centerData, idx) => ({
                name: data.healthCenters[idx],
                type: 'bar',
                coordinateSystem: 'polar',
                data: centerData,
                itemStyle: {
                  color: ['#5470C6', '#91CC75', '#FAC858', '#EE6666'][idx % 4],
                },
                label: {
                  show: true,
                  position: 'middle', // Label position inside the bars
                  formatter: '{b}: {c}',
                  fontSize: 10,
                  color: '#333',
                },
              }));
            case 'doughnut':
              return data.values[0] && data.labels ? [{
                type: 'pie',
                radius: ['40%', '70%'],
                data: data.values[0].map((value, idx) => ({
                  value,
                  name: data.labels[idx],
                })),
                label: { formatter: '{b}: {c} ({d}%)' },
              }] : [];
            case 'funnel':
              return data.values[0] && data.labels ? [{
                type: 'funnel',
                width: '80%',
                data: data.values[0].map((value, idx) => ({
                  value,
                  name: data.labels[idx],
                })),
                label: {
                  show: true,
                  formatter: '{b}: {c}',
                },
              }] : [];
            case 'gauge':
              return data.values[0] && data.labels ? [{
                type: 'gauge',
                detail: { formatter: '{value}%' },
                data: [{ value: data.values[0][0], name: data.labels[0] }],
              }] : [];
            default:
              return [];
          }
        })(),
      };

      return options;
    },

    renderHealthCenterCharts() {
      this.$nextTick(() => {
        this.hcGraphData.forEach((question, index) => {
          const chartId = `chart-${index}`;
          const container = document.getElementById(chartId);
          if (!container) return;

          if (this.chartInstances[chartId]) {
            this.chartInstances[chartId].dispose();
          }

          // Prepare data for each health center and response type
          const labels = question.responses.map((response) => response.response);
          const healthCenters = Array.from(new Set(question.responses.flatMap(response => response.health_centers.map(hc => hc.health_center))));
          const values = healthCenters.map(center =>
            question.responses.map(response =>
              (response.health_centers.find(hc => hc.health_center === center)?.count) || 0
            )
          );

          const chart = echarts.init(container);
          const options = this.getHealthCenterChartOptions(this.chartTypes[`hc_graph-${index}`] || 'bar', {
            question: question.question,
            labels,
            values,
            healthCenters,
          });

          chart.setOption(options);
          this.chartInstances[chartId] = chart;
        });
      });
    },

    removeHealthCenterChart(index) {
      // this.hcGraphData.splice(index, 1);
      this.chartsData.questions.splice(index, 1);
      Toast.success("Graph removed from your dashboard")
      this.renderHealthCenterCharts();
    },

    extractHealthCenterNames(data) {
      const healthCenterNamesSet = new Set();
      data.forEach(question => {
        question.responses.forEach(response => {
          response.health_centers.forEach(center => {
            healthCenterNamesSet.add(center.health_center);
          });
        });
      });
      return Array.from(healthCenterNamesSet);
    },
    getResponseRowspan(responses) {
      return responses.reduce((total, response) => total + response.health_centers.length, 0);
    },
    getHealthCenterCount(centers, centerName, currentCenterName) {
      if (currentCenterName === centerName) {
        const center = centers.find(center => center.health_center === centerName);
        return center ? center.count : 0;
      }
      return 0;
    },
    getTotalRowspan(responses) {
      return responses.reduce((total, response) => total + response.health_centers.length, 0);
    },
    confirmDelete(dashboardId) {
      if (confirm("Are you sure you want to delete this dashboard?")) {
        this.deleteDashboard(dashboardId);
      }
    },
    deleteDashboard(dashboardId) {
      axios
        .delete(`/dashboard/${dashboardId}`)
        .then((response) => {
          if (response.data.status) {
            this.fetchDashboards();
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error deleting dashboard:", error);
        });
    },
    onResizing(chartId, { width, height }) {
      if (!this.chartSizes[chartId]) {
        this.chartSizes[chartId] = {};
      }
      this.chartSizes[chartId].width = width;
      this.chartSizes[chartId].height = height;

      if (this.chartInstances[`chart-${chartId}`]) {
        this.chartInstances[`chart-${chartId}`].resize();
      }
    },
    onResizeStop(chartId, { width, height }) {
      console.log('Resizing stopped for chart:', chartId, 'Width:', width, 'Height:', height);
      this.onResizing(chartId, { width, height }); // Call the resizing function to apply the new size
    },
    onLayoutUpdated(layout) {
      this.layout = layout;
    },

    getChartSize(chartId, dimension, defaultValue) {
      if (dimension === 'height') {
        const chartData = this.hcGraphData[chartId] || {};
        const labelsCount = chartData.responses?.length || 0;

        // Increase height based on the number of labels
        if (labelsCount > 5) {
          return defaultValue + (labelsCount - 5) * 20; // Adjust 20px per extra label as needed
        }
      }
      return (this.chartSizes[chartId] && this.chartSizes[chartId][dimension]) || defaultValue;
    },


    saveChartSettings() {
      console.log('Saving chart sizes:', this.chartSizes);

      const saveData = {
        charts_data: this.chartsData,
        chart_sizes: this.chartSizes,
        chart_types: this.chartTypes,
      };

      axios
        .post(`/dashboard/${this.activeTab.split('-')[1]}/save-settings`, saveData)
        .then((response) => {
          if (response.data.status) {
            Toast.success("Settings saved successfully");
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.error("Error saving settings:", error);
        });
    },
    downloadExcel() {
      const ws = XLSX.utils.aoa_to_sheet(this.prepareExcelData());
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Health Center Report");

      // Apply cell merges
      const merges = this.getMerges();
      if (merges.length) {
        ws['!merges'] = merges;
      }

      XLSX.writeFile(wb, "health_center_report.xlsx");
    },
    prepareExcelData() {
      const data = [];

      // Headers
      const headers = ["Condition", "Question", "Answers", "District", "Area", ...this.healthCenterNames];
      data.push(headers);

      // Data Rows
      this.tableData.forEach(question => {
        question.responses.forEach((response, rIndex) => {
          response.health_centers.forEach((center, cIndex) => {
            const row = [];
            if (rIndex === 0 && cIndex === 0) {
              row.push(question.condition);
              row.push(question.question);
            } else {
              row.push("");
              row.push("");
            }
            if (cIndex === 0) {
              row.push(response.response);
            } else {
              row.push("");
            }
            row.push(center.district);
            row.push(center.location);
            this.healthCenterNames.forEach(name => {
              row.push(this.getHealthCenterCount(response.health_centers, name, center.health_center));
            });
            data.push(row);
          });
        });
      });

      return data;
    },
    getMerges() {
      const merges = [];
      let conditionStart = 1, questionStart = 1, responseStart = 1;

      this.tableData.forEach(question => {
        const responseCount = this.getTotalRowspan(question.responses);
        merges.push({ s: { r: conditionStart, c: 0 }, e: { r: conditionStart + responseCount - 1, c: 0 } });
        merges.push({ s: { r: questionStart, c: 1 }, e: { r: questionStart + responseCount - 1, c: 1 } });

        question.responses.forEach(response => {
          merges.push({ s: { r: responseStart, c: 2 }, e: { r: responseStart + response.health_centers.length - 1, c: 2 } });
          responseStart += response.health_centers.length;
        });

        conditionStart += responseCount;
        questionStart += responseCount;
      });

      return merges;
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "hasPermission",
    }),
    selectedDistrictsDetails() {
      return this.districts.filter((district) => this.selectedDistricts.includes(district.id));
    },
    selectedToolsDetails() {
      return this.tools.filter((tool) => this.selectedTools.includes(tool.id));
    },
    selectedConditionsDetails() {
      return this.conditions.filter((condition) => this.selectedConditions.includes(condition.id));
    },
    selectedQuestionsDetails() {
      return this.questions.filter((question) => this.selectedQuestions.includes(question.id));
    },
  },
  watch: {
    selectedTools: "fetchQuestions",
    selectedConditions: "fetchQuestions",
    selectedDistricts: "fetchQuestions",
    hcGraphData: 'renderHealthCenterCharts',
  },
};
</script>


<style lang="scss" scoped>
:root {
  --color-blue: #4A90E2;
  --color-red: #FF6F61;
  --color-green: #50E3C2;
  --color-yellow: #F8E71C;
  --color-orange: #F5A623;
  --color-purple: #9013FE;
  --color-pink: #FF8C94;
  --color-teal: #4A9ABF;
}

.location-item {
  width: 100%;

  button {
    width: 100%;
    padding: 10px;
  }

  .location-details {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
}

.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 206px;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-radius: 8px;
  margin: 2px 0;
  height: 48px;
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.dashboard-container {
  height: 100%;
  padding: 36px 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .row {
    display: flex;
    gap: 24px;

    .col {
      flex: 1;

      h1 {
        color: var(--blue-original-2);
        font-size: 24px;
        line-height: 20px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      .card {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        border: 1px solid #D0D5DD;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .card-content {
          width: 100%;

          .c1 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .main-count {
              background-color: var(--blue-light);
              padding: 6px 12px;
              border-radius: 30px;
            }

            h3 {
              font-size: 14px;
            }

            p {
              font-weight: regular;
            }
          }

          h3 {
            margin: 0;
            font-size: 1.2em;
            color: var(--blue-500);
            font-weight: regular;
          }

          .count {
            font-size: 14px;
            font-weight: 400;
            color: var(--blue-original);
          }

          select {
            margin-top: 10px;
            padding: 5px;
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            width: 100%;
            font-size: 1em;
            outline: none;
            padding-left: 16px;
            background-color: transparent;

            &:focus {
              border: 1px solid var(--blue-original);
            }
          }
        }
      }
    }

    .usersCol {
      width: 455px;
    }

    .WeeklyDiagnosis {
      width: 100%;
    }

    .tabs {
      width: 70%;
    }

    .questionCol {
      width: 60%;
      margin-top: 24px;
    }

    .conditonAnalytics {}
  }

  .nav-tabs {
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 8px;
    background-color: rgba(33, 72, 192, 10%);
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    .nav-item {
      margin-bottom: -1px;
      font-size: 16px;
      font-weight: 600;
      padding: 8px 16px;
      text-align: center;
      // flex: 1;

      .nav-link {
        display: block;
        padding: 10px 15px;
        color: #555;
        text-decoration: none;
        cursor: pointer;
        font-weight: 400;
        white-space: nowrap;

        &:hover {
          color: #000;
          background-color: #e9ecef;
        }

        &.active {
          color: var(--blue-original);
          background-color: #fff;
          border-radius: 8px;
          font-weight: bold;
          border: 2px solid #e4e7ec;

          a {
            li {
              padding: 12px;
              border-bottom: 4px solid #2148C0;
            }
          }
        }
      }
    }
  }

  .nav-tabs::-webkit-scrollbar {
    height: 8px;
  }

  .nav-tabs::-webkit-scrollbar-thumb {
    background-color: rgba(33, 72, 192, 0.4);
    border-radius: 4px;
  }

  .nav-tabs .nav-item {
    margin-bottom: -1px;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 16px;
    text-align: center;
    flex-shrink: 0;
  }

  .nav-tabs .nav-link {
    display: block;
    padding: 10px 15px;
    color: #555;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    white-space: nowrap;
  }

  .nav-tabs .nav-link:hover {
    color: #000;
    background-color: #e9ecef;
  }

  .nav-tabs .nav-link.active {
    color: var(--blue-original);
    background-color: #fff;
    border-radius: 8px;
    font-weight: bold;
    border: 2px solid #e4e7ec;
  }

  .tab-content {
    padding-top: 20px;
    border-top: none;
  }

  .cards-row {
    margin-bottom: 30px;
  }

  .sidebar {
    width: 245px;
    padding: 8px;
    background-color: #f9fafb;
    border-radius: 8px;
    transition: width 0.3s ease;

    &.sidebar-wide {
      width: 545px;
    }

    .card {
      text-align: left;

      h3 {
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;

          a {
            color: #007bff;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          input[type="checkbox"] {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin-bottom: 10px;
    align-items: center;
    padding-left: 6px;

    .selected-item {
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 5px;
      margin-bottom: 10px;
      padding-left: 6px;
      border: 1px solid #E4E7EC;

      button {
        background: none;
        border: none;
        color: red;
        font-size: 16px;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 6px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .content {
    width: 100%;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .btn-cancel {
    background-color: transparent;
    color: #ca150c;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: #ca150c41;
    }
  }

  .btn-create {
    background-color: #2fab4f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

  .btn-save {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .btn-cancel:hover,
  .btn-create:hover,
  .btn-save:hover {
    opacity: 0.8;
  }

  .dashboard-item {
    display: flex;
    align-items: center;
  }

  .btn-delete {
    color: #ff0d0d;
    border: none;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
  }

  .chart-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .canvas-container {
      position: relative;
      width: 100%;
      height: 100%;

      .vue-draggable-resizable {
        background-color: #fff;
      }

      .card {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        border: 1px solid #D0D5DD;
        height: 100%;
        width: 100%;
        overflow: hidden;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .chart-wrapper {
          width: 100%;
          height: 100%;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;

          canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }

  .chart-header {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    padding: 5px;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;

    &:hover,
    &:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 200ms ease;

      &.save {
        background-color: var(--blue-500);
        color: var(--white);

        &:hover {
          background-color: var(--blue-600);
        }
      }

      &.cancel {
        background-color: var(--gray);
        color: var(--white);

        &:hover {
          background-color: #504e74;
        }
      }
    }
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--gray);
    border-radius: 5px;
  }
}

.container {
  position: relative;
  width: 100%;

  svg {
    background-color: white;
    position: absolute;
    top: 60%;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

select {
  height: 40px;
  padding-left: 15px;
  font-size: 16px;
  border: 1.9px solid lightgray;
  border-radius: 4px;
  color: #39325a;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: transparent;
}

.sub-tabs {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 12px;

  .active {
    li {
      padding: 12px;
      border-bottom: 4px solid #2148C0;
    }

    .sub-link {
      display: flex;
      align-items: center;
      gap: 10px;

      .locationIcon {
        path {
          fill: #1C274C;
          fill-opacity: 1;
        }
      }

      .toolsIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .urgencyIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .conditionIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .questionsIcon {
        path {
          stroke: #1C274C;
          stroke-opacity: 1;
        }

        circle {
          stroke: #1C274C;
          stroke-opacity: 1;
        }
      }

      .statusIcon {
        path {
          fill: #1C274C;
          fill-opacity: 1;
        }
      }

      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0A2472;
      }
    }
  }

  .sub-link {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #d7d8da;
    }
  }

  a {
    text-decoration: none;

    li {
      padding: 12px;
      border-bottom: 4px solid transparent;
    }
  }

  li {
    min-width: 160px;
  }
}

.province {
  width: 100%;
  margin-bottom: 10px;

  .accordion {
    width: 100%;
    padding: 12px;
    background: #f9fafb;
    color: #444;
    cursor: pointer;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    text-align: left;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e9ecef;
    }

    .icon {
      display: flex;
      align-items: center;
      margin-left: auto;

      svg {
        transition: transform 0.3s ease;
      }
    }
  }

  .panel {
    padding: 3px 0px 40px 25px;
    background-color: #f9fafb;
    border-radius: 8px;
    margin-top: 8px;

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 8px 0;
        display: flex;
        align-items: center;

        input[type="checkbox"] {
          margin-right: 10px;
        }
      }

      .loading {
        text-align: center;
        padding: 10px;
        color: #888;
      }
    }
  }
}

.dropdown-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  margin: 0;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 25px;
  // left: 0;
  right: 30px;
  z-index: 100;
  border-radius: 4px;
  padding: 10px;

  a {
    padding: 5px 10px;
    text-decoration: none;
    color: #444;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.save-change-button {
  height: 48px;
  width: 151px;
  border-radius: 8px;
  border: none;
  background-color: rgb(0, 106, 245);
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: 200ms ease;
  margin-left: 85%;
  margin-top: -35px;
  margin-bottom: 8px;
}

.save-change-button:hover {
  background-color: rgb(4, 91, 206);
}

.save-change-button button {
  height: 48px;
  width: 151px;
  border-radius: 8px;
  border: none;
  background-color: rgb(0, 106, 245);
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: 200ms ease;
}

.customize-tab {
  margin: 20px;
}

.customize-tab-question {
  width: 100%;
}

h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--color-blue);
}

.styled-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .styled-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    input[type="checkbox"] {
      margin-right: 10px;
      transform: scale(1.2);
    }

    label {
      font-size: 1em;
      color: #333;
      cursor: pointer;
    }
  }

  .styled-list-item-question {
    padding: 20px;
    font-size: 1.1em;
    width: 90%;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.download-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin-left: -88%;
  margin-bottom: 12px;
}

.download-button:hover {
  background-color: #0056b3;
}

.download-button:active {
  background-color: #004085;
}

.chart-preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.chart-preview-modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 1200px;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 80vh;
}

.chart-preview-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }
}

.chart-preview-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.chart-preview-canvas-container {
  position: relative;
  padding-top: 56.25%;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.chart-preview-canvas-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.chart-preview-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn-preview {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
}

.chart-preview-modal-content {
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}


.chart-preview-container {

  .chartjs-render-monitor,
  .chartjs-label,
  .chartjs-legend-text,
  .chartjs-title,
  .chartjs-xAxis,
  .chartjs-yAxis {
    color: black !important;
  }
}

.chart-preview-container {
  padding-right: 10px;
}

.chart-preview-canvas-container {
  canvas {
    color: black !important;
  }
}

.close-header {
  position: absolute;
  top: 14px;
  left: 8px;
  background: white;
  padding: 0px;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  // margin-right: auto;
}

// .close-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

.close-btn svg {
  fill: red;
}
</style>
